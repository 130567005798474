/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { Logger } from '@feature-hub/core';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service/dist/v2/types';
import { TrackingServiceV3 } from '@volkswagen-onehub/oneaudi-os-onesight';
import { App } from '@volkswagen-onehub/oneaudi-os-react';
import FeatureAppRoot from './components/FeatureAppRoot';
import { EditorJsonContent } from './contexts/ContentInterfaces';
import { TrackingServiceEnvironment } from './utilTrackingHelpers/TrackingHelperWrapper';

export interface FeatureAppProps {
  trackingService?: TrackingServiceV2 | TrackingServiceV3;
  loggerService?: Logger;
  // eslint-disable-next-line
  config?: EditorJsonContent;
  environment: string;
  featureAppId: string;
}
export type TrackingData = {
  trackingService: TrackingServiceEnvironment;
};
// eslint-disable-next-line
const FeatureApp: React.FC<FeatureAppProps> = ({ config, ...inherited }: FeatureAppProps) => {
  const { trackingService, loggerService, environment, featureAppId } = inherited;
  const app = {
    name: __FEATURE_APP_NAME__,
    version: __FEATURE_APP_VERSION__,
  };
  // eslint-disable-next-line
  const appConfig: { [k: string]: any } = { appName: __FEATURE_APP_NAME__ };
  return (
    <App config={appConfig} app={app} {...inherited}>
      <FeatureAppRoot
        environment={environment}
        trackingService={trackingService}
        loggerService={loggerService ?? console}
        config={config}
        featureAppId={featureAppId}
      />
    </App>
  );
};
export default FeatureApp;
