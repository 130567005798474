// eslint-disable-next-line
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
// eslint-disable-next-line
import React from 'react';
import { ThemeProvider } from '@oneaudi/unified-web-common';
import { Text, TextLink } from '@oneaudi/unified-web-components';
import { UeElement } from '@oneaudi/falcon-tools';
import { ThemeName } from '@oneaudi/unified-web-common/dist/cjs/components/theme-provider/theme';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { TextBlock, TitleTextBlock, CTAContainer } from './style';
import { constants } from '../../../constants';

interface TextBlockProps {
  ctaLink: string;
  ctaText: string;
  title: string;
  bodyText: string;
  theme: string;
  openIn: string;
  width?: number | undefined;
  ariaLabel?: string;
  // eslint-disable-next-line
  trackExitLink: Function;
  // eslint-disable-next-line
  handleClick: Function;
  slideIndex: number;
}
// eslint-disable-next-line
export const FeatureGalleryTextBlock: React.FC<TextBlockProps> = (props) => {
  const { bodyText, ctaLink, ctaText, title, theme, openIn, ariaLabel, width, ...ueProps } = props;

  const themeProvider = () => {
    const { darkTheme } = constants.theme;
    if (theme === darkTheme) {
      return 'dark';
    }
    return 'ligth';
  };

  function setCtaUrl(link: string) {
    if (!(link.indexOf('/') === 0)) {
      return link;
    }
    const url = typeof window !== 'undefined' && window.location.origin;
    return typeof window !== 'undefined' ? url + link : undefined;
  }

  return (
    <ThemeProvider theme={{ name: themeProvider() as ThemeName }} {...ueProps}>
      <TextBlock theme={theme} className="fg-text-block-content">
        <TitleTextBlock width={width}>
          <Text variant={{ type: 'copy', order: '1', style: 'bold' }} {...ueProps}>
            <UeElement type="text" property="title" label="Title">
              {renderTextWithFootnotesReferencesV2(title)}
            </UeElement>
          </Text>
          <Text tag="p" variant={{ type: 'copy', order: '1', style: 'normal' }} {...ueProps}>
            <UeElement type="text" property="bodyText" label="Body text">
              {renderTextWithFootnotesReferencesV2(bodyText)}
            </UeElement>
          </Text>
        </TitleTextBlock>
        {ctaText ? (
          <CTAContainer>
            <TextLink
              variant="standalone"
              target={openIn !== 'Same tab' ? 'self' : '_blank'}
              href={setCtaUrl(ctaLink) as string}
              aria-label={ariaLabel}
              onClick={() => {
                // eslint-disable-next-line
                props.trackExitLink(ctaText, ctaLink);
              }}
              size="md"
              iconPosition="trailing"
              icon="forward"
            >
              <UeElement type="text" property="cta1_ctaText1" label="CTA text">
                {ctaText}
              </UeElement>
            </TextLink>
          </CTAContainer>
        ) : null}
      </TextBlock>
    </ThemeProvider>
  );
};
