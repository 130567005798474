/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useContext } from 'react';
import { Text } from '@oneaudi/unified-web-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { VueServiceHelper, WltpProps } from '../../../helper/consumptionEmission';
import { generateUUID } from '../../../helper/generateUUID';
import { WLTPContainer, MediaDisclaimerContainer } from './style';
import { ContentContext } from '../../contexts';

type WLTPComponentsProps = {
  wltpTextValues: string[];
  mediaDisclaimer: string[];
  theme: string;
  // handleClick: Function;
};
// eslint-disable-next-line
const WLTPComponent: React.FC<WLTPComponentsProps> = (props) => {
  // const { mediaDisclaimer, wltpTextValues, theme, handleClick } = props;
  const { mediaDisclaimer, wltpTextValues, theme, ...ueProps } = props;
  // eslint-disable-next-line
  const context: any = useContext(ContentContext);
  const { vueFormatterService } = context;

  const { localeService } = vueFormatterService;

  const [vueTexts, setVueTexts] = React.useState([] as WltpProps[]);

  useEffect(() => {
    if (wltpTextValues)
      VueServiceHelper.getConsumptionAndEmission(
        wltpTextValues,
        setVueTexts,
        vueFormatterService,
        localeService,
      );
  }, [wltpTextValues]);

  return (
    // eslint-disable-next-line
    <div {...ueProps}>
      {mediaDisclaimer?.length > 0 ||
      (vueTexts && wltpTextValues.length > 0) ||
      typeof mediaDisclaimer === 'string' ? (
        <WLTPContainer
          className={`fg-wltp-content `}
          key="fg-wltp-content"
          theme={theme}
          {...ueProps}
        >
          {vueTexts &&
            vueTexts?.map(
              (
                {
                  formattedConsumption,
                  formattedEmission,
                  formattedCo2Class,
                  formattedDischargedCo2Class,
                  formattedDischargedConsumption,
                },
                index,
              ) => (
                // eslint-disable-next-line react/no-array-index-key
                <Text tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }} key={index}>
                  <span>
                    {formattedConsumption && (
                      <span className="fg-wltp-items" tabIndex={-1} key={generateUUID()}>
                        {formattedConsumption};
                      </span>
                    )}
                    {formattedEmission && (
                      <span className="fg-wltp-items" tabIndex={-1} key={generateUUID()}>
                        {formattedEmission};
                      </span>
                    )}
                    {formattedCo2Class && (
                      <span className="fg-wltp-items" tabIndex={-1} key={generateUUID()}>
                        {formattedCo2Class}
                        {formattedDischargedConsumption || formattedDischargedCo2Class ? ';' : ''}
                      </span>
                    )}
                    {formattedDischargedConsumption && (
                      <span className="fg-wltp-items" tabIndex={-1} key={generateUUID()}>
                        {formattedDischargedConsumption}
                        {formattedDischargedCo2Class ? ';' : ''}
                      </span>
                    )}
                    {formattedDischargedCo2Class && (
                      <span className="fg-wltp-items" tabIndex={-1} key={generateUUID()}>
                        {formattedDischargedCo2Class}
                      </span>
                    )}
                  </span>
                </Text>
              ),
            )}

          <MediaDisclaimerContainer
            style={{ display: mediaDisclaimer?.length ? 'block' : 'none' }}
            key="mediaDisclaimer"
          >
            {mediaDisclaimer &&
              (typeof mediaDisclaimer === 'string' ? (
                <Text tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }}>
                  {renderTextWithFootnotesReferencesV2(mediaDisclaimer)}
                </Text>
              ) : (
                mediaDisclaimer?.map((disclaimer, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Text key={index} tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }}>
                    {renderTextWithFootnotesReferencesV2(disclaimer)}
                  </Text>
                ))
              ))}
          </MediaDisclaimerContainer>
        </WLTPContainer>
      ) : null}
    </div>
  );
};

export default WLTPComponent;
